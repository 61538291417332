const initNavbarShrink = () => {
    const hamburger = document.getElementById('hamburger');
    const navLinks = document.getElementById('burger-list');
    const svgClose = document.getElementById('svg-close');
    const cartNavButtonClose = document.getElementById('cart-button-close');
    const cartNavButton = document.getElementById('shopping-cart-button-navbar');
    const cart = document.getElementById('shopping-modal');
    const cartButtonMobile = document.getElementById('cart-button-mobile');
    hamburger.addEventListener('click', () => {
      navLinks.classList.toggle('show');
    });
    if (svgClose != null) {
      svgClose.addEventListener('click', () => {
        cart.style.display = "none";
      });
    }
    if (cartNavButtonClose != null) {
      cartNavButtonClose.addEventListener('click', () => {
        cart.style.display = "none";
      });
    }
    if (cartNavButton != null) {
      cartNavButton.addEventListener('click', () => {
        cart.style.display = "flex";
      });
    }
}

export { initNavbarShrink }
