const scrollToActiveChapter = () => {
  const hasChapters = document.getElementsByClassName('current-chapter-nav').length > 0;
  if (hasChapters) {
    document.querySelector('.current-chapter-nav').scrollIntoView(false);
  }
}

const mobileChapterAnimation = () => {
  const chaptersList = document.getElementById("chapters-ul");
  const arrowSVG = document.getElementById("svg-arrow");
  const titleDiv = document.getElementById("title-div")
  titleDiv.addEventListener("click", function() {
    arrowSVG.classList.toggle("svg-opened");
    if (chaptersList.classList.contains("ul-opened")) {
      chaptersList.classList.add("ul-closed");
    } else {
      chaptersList.classList.remove("ul-closed");
    }
    chaptersList.classList.toggle("ul-opened");
    scrollToActiveChapter();
  })

}

function activateVideos() {
  const iframes = document.querySelectorAll('.video__iframe');
  iframes.forEach((iframe) => {
    iframe.src = iframe.dataset.src;
  });
}

function attachEvents() {
  const notices = document.querySelectorAll('.video__notice');
  notices.forEach((notice) => {
    notice.addEventListener('submit', (event) => {
      activateVideos();
      Cookies.set('youtube-consent', 'true', { expires: 365, samesite: 'strict' });
      event.preventDefault();
    });
  });
}

export { mobileChapterAnimation, scrollToActiveChapter, attachEvents, activateVideos }
