window.addEventListener("trix-file-accept", function(event) {
  const acceptedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/bmp', 'audio/mpeg']
  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault()
    alert("Le fichier sélectionné n'est pas une image au format jpg, png ou bmp ou un fichier audio mp3.")
  }
})




import Trix from "trix"
import Rails from "@rails/ujs"


/* Prevent default filename+size captions */
Trix.config.attachments.preview.caption.name = false
Trix.config.attachments.preview.caption.size = false
Trix.config.lang = {
   bold: "Gras",
   bullets: "Liste à puces",
   byte:  "Byte",
   bytes: "Bytes",
   captionPlaceholder: "Ajouter une légende...",
   code: "Code",
   heading1: "Titre",
   indent: "Increase Level",
   italic: "Italique",
   link: "Lien",
   numbers: "Liste numérotée",
   outdent: "Decrease Level",
   quote: "Citation",
   redo: "Refaire",
   remove: "Enlever",
   strike: "Barré",
   undo: "Annuler",
   unlink: "Enlever le lien",
   url: "URL",
   urlPlaceholder: "Entrez une URL...",
   GB: "GB",
   KB: "KB",
   MB: "MB",
   PB: "PB",
   TB: "TB"}

Trix.config.textAttributes.center = {
    style: { display: `flex`, justifyContent: `center`, textAlign: `center` },
    inheritable: true,
    parser: element => element.style.display == `flex`,
    parser: element => element.style.justifyContent == `center`,
    parser: element => element.style.textAlign == `center`
}

Trix.config.textAttributes.italic = {
    style: { fontStyle: `italic` },
    inheritable: true,
    parser: element => element.style.fontStyle == `italic`
}

Trix.config.textAttributes.bold = {
    style: { fontWeight: `bold` },
    inheritable: true,
    parser: element => element.style.fontWeight == `bold`
}

Trix.config.blockAttributes.imgcontainerd = {
    tagName: "imgcontainerd",
    nestable: true
}

Trix.config.blockAttributes.imgcontainerg = {
    tagName: "imgcontainerg",
    nestable: true
}

Trix.config.blockAttributes.questionbubble = {
    tagName: "questionbubble",
    nestable: true
}

Trix.config.blockAttributes.exclamationbubble = {
    tagName: "exclamationbubble",
    nestable: true
}
/* insert the button visual in the default toolbar */
addEventListener("trix-initialize", function(event) {
    var buttonHTML = `<button type="button" data-trix-attribute="center" class="trix-button">Centrer</button>`
    event.target.toolbarElement.
    querySelector(".trix-button-group").
    insertAdjacentHTML("beforeend", buttonHTML);
})

addEventListener("trix-initialize", function(event) {
    var buttonHTML = `<button type="button" data-trix-attribute="imgcontainerg" class="trix-button">G</button>`
    event.target.toolbarElement.
    querySelector(".trix-button-group--block-tools").
    insertAdjacentHTML("beforeend", buttonHTML);
})

addEventListener("trix-initialize", function(event) {
    var buttonHTML = `<button type="button" data-trix-attribute="imgcontainerd" class="trix-button">D</button>`
    event.target.toolbarElement.
    querySelector(".trix-button-group--block-tools").
    insertAdjacentHTML("beforeend", buttonHTML);
})

addEventListener("trix-initialize", function(event) {
    var buttonHTML = `<button type="button" data-trix-attribute="questionbubble" class="trix-button">(?)</button>`
    event.target.toolbarElement.
    querySelector(".trix-button-group--block-tools").
    insertAdjacentHTML("beforeend", buttonHTML);
})

addEventListener("trix-initialize", function(event) {
    var buttonHTML = `<button type="button" data-trix-attribute="exclamationbubble" class="trix-button">(!)</button>`
    event.target.toolbarElement.
    querySelector(".trix-button-group--block-tools").
    insertAdjacentHTML("beforeend", buttonHTML);
})
